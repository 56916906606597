import { useForm } from "@mantine/form";
import {
  Button,
  Select,
  Group,
  Avatar,
  Text,
  Slider,
  Box,
  createStyles,
  SimpleGrid,
  Textarea,
  Badge,
  Divider,
  Image,
  Alert,
} from "@mantine/core";
import { forwardRef, useRef } from "react";
import { FaSave } from "react-icons/fa";
import { BsInfoLg } from "react-icons/bs";
import { IconCheck } from "@tabler/icons";
import { useState } from "react";
import { Dropzone, IMAGE_MIME_TYPE, FileWithPath } from "@mantine/dropzone";

const useStyles = createStyles((theme) => ({
  sendButton: {
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.colors.gray[8]
        : theme.colors.dark[7],
  },
}));

const data = [
  {
    image: "https://tipushiba.hu/files/logo/l_alfa.png",
    label: "Alfa Romeo",
    value: "Alfa Romeo",
    description: "",
  },

  {
    image: "https://tipushiba.hu/files/logo/l_audi.png",
    label: "Audi",
    value: "Audi",
    description: "",
  },
  {
    image: "https://tipushiba.hu/files/logo/l_renault.png",
    label: "Renault",
    value: "Renault",
    description: "",
  },
  {
    image: "https://tipushiba.hu/files/logo/l_suzuki.png",
    label: "Suzuki",
    value: "Suzuki",
    description: "",
  },
];

interface ItemProps extends React.ComponentPropsWithoutRef<"div"> {
  image: string;
  label: string;
  description: string;
}

// eslint-disable-next-line react/display-name
const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ image, label, description, ...others }: ItemProps, ref) => (
    <div
      ref={ref}
      {...others}
    >
      <Group noWrap>
        <Avatar src={image} />

        <div>
          <Text size="sm">{label}</Text>
          <Text
            size="xs"
            opacity={0.65}
          >
            {description}
          </Text>
        </div>
      </Group>
    </div>
  )
);

const MennyitMentForm = (props: { setNotification: any }) => {
  const { classes } = useStyles();

  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const textLength = textareaRef.current?.value.length ?? 0;

  const [files, setFiles] = useState<FileWithPath[]>([]);
  const previews = files.map((file, index) => {
    const imageUrl = URL.createObjectURL(file);
    return (
      <Image
        key={index}
        src={imageUrl}
        imageProps={{ onLoad: () => URL.revokeObjectURL(imageUrl) }}
        alt=""
      />
    );
  });

  const form = useForm({
    initialValues: {
      brand: "",
      model: "",
      year: 1970,
      description: "",
    },
    validate: (values) => ({
      brand: brandValidate(values),
      model: modelValidate(values),
      description: descValidate(values),
    }),
  });

  function brandValidate(values: { brand: string }) {
    if (values.brand?.length > 2) {
      return null;
    } else {
      return "A mező kitöltèse kötelező!";
    }
  }

  function modelValidate(values: { model: string }) {
    if (form.isDirty("brand") && values.model?.length > 2) {
      return null;
    }
    if (form.isDirty("brand") && values.model?.length < 2) {
      return "A mező kitöltèse kötelező!";
    }

    return null;
  }

  function descValidate(values: { description: string }) {
    if (values.description?.length === 0) {
      return "A mező kitöltèse kötelező!";
    }
    if (values.description?.length < 50) {
      return "Túl rövid bejegyzés! Min. 50 karakter hosszú legyen.";
    }

    return null;
  }

  const handleSubmit = (values: typeof form.values) => {
    console.log(values);

    if (form.isValid()) {
      props.setNotification({
        open: true,
        title: "Hirdetes mentve.",
        icon: <IconCheck size={18} />,
        color: "teal",
      });

      form.reset();
    }
  };

  return (
    <>
      <Alert icon={<BsInfoLg size={16} />}>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua.
      </Alert>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Box mt="lg">
          <Textarea
            ref={textareaRef}
            placeholder="Leírás"
            label="Leírás"
            autosize
            minRows={4}
            {...form.getInputProps("description")}
          />
          <Badge
            color={textLength < 50 ? "red" : "green"}
            style={{ textTransform: "none" }}
          >
            Min. 50 karakter / {textLength}
          </Badge>
        </Box>

        <Box mt="lg">
          <Divider
            my="xs"
            variant="dashed"
            labelPosition="center"
            label="Képek"
          />

          <Dropzone
            accept={IMAGE_MIME_TYPE}
            onDrop={setFiles}
          >
            <Text align="center">
              Fényképek hozzáadása a kilométeróra állásáról
            </Text>
            <Text
              align="center"
              fz="sm"
            >
              Húzd ide vagy kattints a Tallózás gombra
            </Text>
            <Box
              mt="md"
              sx={(theme) => ({
                textAlign: "center",
              })}
            >
              <Button>Tallózás</Button>
            </Box>
          </Dropzone>

          <SimpleGrid
            cols={4}
            breakpoints={[{ maxWidth: "sm", cols: 1 }]}
            mt={previews.length > 0 ? "xl" : 0}
          >
            {previews}
          </SimpleGrid>
        </Box>

        <Divider mt={15} />
        <Select
          mt="sm"
          label="Állapot"
          placeholder="Kérlek válassz egyet"
          data={[
            { value: "value1", label: "Üzemel, forgalomban" },
            { value: "value2", label: "Üzemel, nincs forgalomban" },
            { value: "value3", label: "Nem üzemel, forgalomban" },
            { value: "value4", label: "Nem üzemel, nincs forgalomban" },
          ]}
        />
        <SimpleGrid
          cols={2}
          mt={10}
        >
          <div>
            <Select
              label="Márka"
              placeholder="Válassz mákát"
              itemComponent={SelectItem}
              data={data}
              searchable
              maxDropdownHeight={400}
              nothingFound="Nobody here"
              filter={(value, item) =>
                item?.label
                  ?.toLowerCase()
                  .includes(value.toLowerCase().trim()) ||
                item.description
                  .toLowerCase()
                  .includes(value.toLowerCase().trim())
              }
              {...form.getInputProps("brand")}
            />
          </div>
          <div>
            <Select
              style={{ display: form.isDirty("brand") ? "block" : "none" }}
              label="Típus"
              placeholder="Válassz típust"
              searchable
              nothingFound="No options"
              data={["Típus 1", "Típus 2", "Típus 3"]}
              {...form.getInputProps("model")}
            />
          </div>
        </SimpleGrid>

        <Box mt={60}>
          <Slider
            labelAlwaysOn
            defaultValue={1970}
            min={1970}
            max={2022}
            color="dark"
            {...form.getInputProps("year")}
          />
          <Text fz="sm">Évjárat</Text>
        </Box>

        <Divider mt={15} />
        <Button
          className={classes.sendButton}
          mt={25}
          type="submit"
          fullWidth
          color="dark"
          leftIcon={<FaSave />}
        >
          Mentés
        </Button>
      </form>
    </>
  );
};

export default MennyitMentForm;
