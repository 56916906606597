import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
   name: 'user',
   initialState: {
      auth: false,
      name: null,
      photo: null,
   },
   reducers: {
      setUserDefaultData: (state, action) => {
         state.auth = true;
         state.name = action.user.name;
         state.photo = action.user.photo;
      },
      logout: (state, action) => {
         state.auth = false;
         state.name = action.user.name;
         state.photo = action.user.photo;
      },
   },
});

export const selectUser = (state) => state.user;

export default userSlice.reducer;