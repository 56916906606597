import { useState, useEffect } from "react";
import { AppProps } from "next/app";
import Head from "next/head";
import {
  MantineProvider,
  ColorSchemeProvider,
  ColorScheme,
} from "@mantine/core";
import { SpotlightProvider } from "@mantine/spotlight";
import { NotificationsProvider } from "@mantine/notifications";
import { Search } from "tabler-icons-react";
import FooterMenu from "../components/footerMenu";
import { useColorScheme, useLocalStorage } from "@mantine/hooks";

import useAuth from "../lib/auth";

import store from "../lib/store";
import { Provider, useDispatch } from "react-redux";

import "../styles/globals.css";

const InitUserStore = () => {
  const dispatch = useDispatch();
  const auth = useAuth();

  auth.userIsLoggedIn().then((result) => {
    if (!result) {
      dispatch({
        type: "user/logout",
        user: { auth: false, name: null, photo: null },
      });
    } else {
      auth.getUserData();
    }
  });

  console.log(store.getState());

  return <></>;
};

export default function App(props: AppProps) {
  const { Component, pageProps } = props;

  const preferredColorScheme = useColorScheme();
  const [colorScheme, setColorScheme] = useState<ColorScheme>("light");
  const toggleColorScheme = (value?: ColorScheme) =>
    setColorScheme(value || (colorScheme === "dark" ? "light" : "dark"));
  const [value, setLSValue] = useLocalStorage({
    key: "color-scheme",
    defaultValue: "x",
  });

  if (colorScheme !== preferredColorScheme && value == "x") {
    setColorScheme(preferredColorScheme);
    setLSValue(preferredColorScheme);
  }

  useEffect(() => {
    if (typeof window !== "undefined") {
      const lSValue = localStorage.getItem("color-scheme")?.replace(/"/g, "");

      lSValue === "dark"
        ? toggleColorScheme("dark")
        : toggleColorScheme("light");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Head>
        <title>Page title</title>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
        <meta
          name="description"
          content="Generated by create next app"
        />
        <link
          rel="icon"
          href="/favicon.ico"
        />
      </Head>

      <ColorSchemeProvider
        colorScheme={colorScheme}
        toggleColorScheme={toggleColorScheme}
      >
        <MantineProvider
          withGlobalStyles
          withNormalizeCSS
          theme={{
            colorScheme,
            breakpoints: {
              xs: 576,
              sm: 768,
              md: 840,
              lg: 1200,
              xl: 1400,
            },
            components: {
              Modal: {
                styles: {
                  modal: {
                    border: `1px solid ${
                      colorScheme === "dark" ? "#373A40" : "#dee2e6"
                    }`,
                  },
                },
              },
              Header: {
                styles: {
                  root: {
                    zIndex: 200,
                  },
                },
              },
              Drawer: {
                styles: {
                  title: {
                    fontWeight: "bold",
                  },
                },
              },
              Slider: {
                styles: {
                  bar: {
                    backgroundColor: `${
                      colorScheme === "dark" ? "#7e7e7e" : "#000"
                    }`,
                  },
                },
              },
            },
          }}
        >
          <NotificationsProvider position="top-right">
            <SpotlightProvider
              actions={[]}
              searchIcon={<Search size={18} />}
              searchPlaceholder="Keresés..."
              nothingFoundMessage="Nincs találat..."
              shortcut={["ctrl + K", "mod + K"]}
            >
              <Provider store={store}>
                <InitUserStore />
                <Component {...pageProps} />
                <FooterMenu />
              </Provider>
            </SpotlightProvider>
          </NotificationsProvider>
        </MantineProvider>
      </ColorSchemeProvider>
    </>
  );
}
