import { Button, Menu, createStyles } from "@mantine/core";
import { useState } from "react";
import { GoPlus } from "react-icons/go";
import { BsSpeedometer2 } from "react-icons/bs";
import { Messages, FloatLeft, FloatNone } from "tabler-icons-react";
import AddButtonStyles from "../styles/addButtonStyle";

import AddDrawerForm from "../layouts/add_forms/drawer";

const useStyles = AddButtonStyles;

const AddBtn = () => {
  const { classes } = useStyles();

  const [fromOpen, setFromOpen] = useState(false);
  const [fromProps, setFromProps] = useState({ title: "", form: "" });

  const handleChangeForm = (state: boolean, title: string, form: string) => {
    setFromOpen(state);
    setFromProps({ title: title, form: form });
  };

  return (
    <>
      <Menu
        shadow="md"
        position="top-end"
        offset={1}
        withArrow
        closeOnClickOutside={true}
      >
        <Menu.Target>
          <Button
            variant="outline"
            className={classes.addBtn}
          >
            <GoPlus></GoPlus>
            <span className={classes.addBtnTxt}>Hozzáadás</span>
          </Button>
        </Menu.Target>

        <Menu.Dropdown>
          <Menu.Item
            icon={<FloatLeft />}
            onClick={() =>
              handleChangeForm(true, "Bejegyzés létrehozása", "post")
            }
          >
            Blog bejegyzés
          </Menu.Item>
          <Menu.Item
            icon={<Messages />}
            onClick={() =>
              handleChangeForm(
                true,
                "Kérdés a közösséghez hozzáadása",
                "question"
              )
            }
          >
            Kérdés a közösséghez
          </Menu.Item>
          <Menu.Item
            icon={<FloatNone />}
            onClick={() => handleChangeForm(true, "Hirdetés hozzáadása", "ads")}
          >
            Hirdetés feladás
          </Menu.Item>
          <Menu.Item
            icon={<BsSpeedometer2 />}
            onClick={() =>
              handleChangeForm(true, "Mennyit ment hozzáadása", "mennyit_ment")
            }
          >
            Mennyit ment?
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>

      <AddDrawerForm
        drawerTitle={fromProps.title}
        drawerState={fromOpen}
        modDrawerState={setFromOpen}
        formType={fromProps.form}
      ></AddDrawerForm>
    </>
  );
};

export default AddBtn;
