import { Button } from "@mantine/core";
import loginButtonStyle from "../styles/loginButtonStyle";
import { useEffect } from "react";
import { useRouter } from "next/router";
import Link from "next/link";
import { FiLogIn } from "react-icons/fi";

const useStyles = loginButtonStyle;

const LoginButton = () => {
  const router = useRouter();
  const { classes } = useStyles();

  useEffect(() => {
    router.prefetch("/login");
  }, []);

  return (
    <Link href="/login">
      <Button
        variant="outline"
        classNames={{
          root: classes.loginBtn,
          label: classes.buttonLabel,
          leftIcon: classes.buttonIcon,
        }}
        leftIcon={<FiLogIn />}
      >
        <span>Bejelentkezés</span>
      </Button>
    </Link>
  );
};

export default LoginButton;
