import { createStyles } from "@mantine/core";

const footerStyles = createStyles((theme) => ({
  footerRoot: {
    position: "fixed",
    bottom: 0,
    left: 0,
    width: "100%",
  },
  footerInner: {
    backgroundColor: `${
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.colors.gray[1]
    }`,
    borderTop: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.gray[8] : theme.colors.gray[5]
    }`,
    padding: "5px 15px",
  },
  homeBtn: {
    paddingLeft: "10px",
    paddingRight: "10px",
    cursor: "pointer",
    color: `${theme.colorScheme === "dark" ? theme.colors.gray[2] : "#000"}`,
    borderColor: `${
      theme.colorScheme === "dark" ? theme.colors.gray[7] : theme.colors.gray[5]
    }`,
  },
  searchBtn: {
    paddingLeft: "10px",
    paddingRight: "10px",
    cursor: "pointer",
    color: `${theme.colorScheme === "dark" ? theme.colors.gray[2] : "#000"}`,
    borderColor: `${
      theme.colorScheme === "dark" ? theme.colors.gray[7] : theme.colors.gray[5]
    }`,
  },
}));

export default footerStyles;
