import { Drawer, ScrollArea, Box } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { useState, useEffect } from "react";

import BlogForm from "./blog";
import AdsForm from "./ads";
import QuestionForm from "./question";
import MennyitMentForm from "./mennyitMent";

const AddDrawerForm = (props: {
  drawerTitle: string;
  drawerState: boolean;
  modDrawerState: any;
  formType: string;
}) => {
  interface Notification {
    open: boolean;
    title: string;
    message?: string | null;
    icon?: string | undefined;
    color?: string | undefined;
  }

  const [notification, setNotification] = useState<Notification>({
    open: false,
    title: "",
  });

  useEffect(() => {
    if (notification.open && notification.title.length > 0) {
      showNotification({
        title: notification.title,
        message: notification.message,
        icon: notification.icon,
        color: notification.color,
        onClose: () =>
          setNotification({
            open: false,
            title: "",
            message: null,
            icon: undefined,
            color: undefined,
          }),
      });
    }
  }, [notification]);

  const renderSwitch = (param: string) => {
    switch (param) {
      case "post":
        return <BlogForm setNotification={setNotification}></BlogForm>;
      case "ads":
        return <AdsForm setNotification={setNotification}></AdsForm>;
      case "question":
        return <QuestionForm setNotification={setNotification}></QuestionForm>;
      case "mennyit_ment":
        return (
          <MennyitMentForm setNotification={setNotification}></MennyitMentForm>
        );
      default:
        return "foo";
    }
  };

  return (
    <>
      <Drawer
        opened={props.drawerState}
        onClose={() => props.modDrawerState(false)}
        title={props.drawerTitle}
        padding="xl"
        size="xl"
      >
        <ScrollArea
          style={{
            width: "100%",
            height: "100vh",
            paddingBottom: "80px",
          }}
          type="auto"
        >
          <Box
            pl="sm"
            pr="lg"
          >
            {renderSwitch(props.formType)}
          </Box>
        </ScrollArea>
      </Drawer>
    </>
  );
};

export default AddDrawerForm;
