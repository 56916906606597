import { createStyles } from "@mantine/core";

const loginButtonStyle = createStyles((theme) => ({
  loginBtn: {
    backgroundColor: "#CC0033",
    border: 0,
    fontSize: 16,

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[3]
          : theme.colors.dark[6],
    },

    [`@media (max-width: ${theme.breakpoints.xs}px)`]: {
      //display: "none",
    },
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      paddingLeft: "12px",
      paddingRight: "12px",
    },

    label: {
      color: "red !important",
    },
  },
  buttonLabel: {
    color: "white",
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      display: "none",
    },
  },
  buttonIcon: {
    color: "white",
  },
}));

export default loginButtonStyle;
