import { useForm } from "@mantine/form";
import {
  Button,
  Select,
  Group,
  Avatar,
  Text,
  Slider,
  Box,
  createStyles,
  SimpleGrid,
  Textarea,
  Badge,
  Divider,
  Card,
} from "@mantine/core";
import { forwardRef, useRef } from "react";
import { FaSave } from "react-icons/fa";
import { IconCheck } from "@tabler/icons";

const useStyles = createStyles((theme) => ({
  sendButton: {
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.colors.gray[8]
        : theme.colors.dark[7],
  },
}));

const data = [
  {
    image: "https://tipushiba.hu/files/logo/l_alfa.png",
    label: "Alfa Romeo",
    value: "Alfa Romeo",
    description: "",
  },

  {
    image: "https://tipushiba.hu/files/logo/l_audi.png",
    label: "Audi",
    value: "Audi",
    description: "",
  },
  {
    image: "https://tipushiba.hu/files/logo/l_renault.png",
    label: "Renault",
    value: "Renault",
    description: "",
  },
  {
    image: "https://tipushiba.hu/files/logo/l_suzuki.png",
    label: "Suzuki",
    value: "Suzuki",
    description: "",
  },
];

interface ItemProps extends React.ComponentPropsWithoutRef<"div"> {
  image: string;
  label: string;
  description: string;
}

// eslint-disable-next-line react/display-name
const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ image, label, description, ...others }: ItemProps, ref) => (
    <div
      ref={ref}
      {...others}
    >
      <Group noWrap>
        <Avatar src={image} />

        <div>
          <Text size="sm">{label}</Text>
          <Text
            size="xs"
            opacity={0.65}
          >
            {description}
          </Text>
        </div>
      </Group>
    </div>
  )
);

const QuestionForm = (props: { setNotification: any }) => {
  const { classes } = useStyles();

  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const textLength = textareaRef.current?.value.length ?? 0;

  const form = useForm({
    initialValues: {
      brand: "",
      model: "",
      year: 1970,
      description: "",
    },
    validate: (values) => ({
      brand: brandValidate(values),
      model: modelValidate(values),
      description: descValidate(values),
    }),
  });

  function brandValidate(values: { brand: string }) {
    return null;
  }

  function modelValidate(values: { model: string }) {
    if (form.isDirty("brand") && values.model.length > 2) {
      return null;
    }
    if (form.isDirty("brand") && values.model.length < 2) {
      return "A mező kitöltèse kötelező!";
    }

    return null;
  }

  function descValidate(values: { description: string }) {
    if (values.description.length === 0) {
      return "A mező kitöltèse kötelező!";
    }
    if (values.description.length < 30) {
      return "Túl rövid bejegyzés! Min. 30 karakter hosszú legyen.";
    }

    return null;
  }

  const handleSubmit = (values: typeof form.values) => {
    console.log(values);

    if (form.isValid()) {
      props.setNotification({
        open: true,
        title: "Bejegyzés mentve.",
        icon: <IconCheck size={18} />,
        color: "teal",
      });

      form.reset();
    }
  };

  return (
    <>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Box>
          <Textarea
            ref={textareaRef}
            placeholder="Ide írhatod a kérdésed amivel kapcsolatban választ szeretnél kapni, legyen az szervízelési vagy egyébb autós témában."
            label="Kéredésed a közösséghez"
            autosize
            minRows={4}
            {...form.getInputProps("description")}
          />
          <Badge
            color={textLength < 30 ? "red" : "green"}
            style={{ textTransform: "none" }}
          >
            Min. 30 karakter / {textLength}
          </Badge>
        </Box>

        <Card
          mt={25}
          px={25}
          radius="md"
          withBorder
        >
          <Divider
            mb="xs"
            label="Opcionális adatok"
            labelPosition="center"
          />
          <SimpleGrid
            cols={2}
            mt={10}
          >
            <div>
              <Select
                label="Márka"
                placeholder="Válassz mákát"
                itemComponent={SelectItem}
                data={data}
                searchable
                maxDropdownHeight={400}
                nothingFound="Nobody here"
                filter={(value, item) =>
                  item?.label
                    ?.toLowerCase()
                    .includes(value.toLowerCase().trim()) ||
                  item.description
                    .toLowerCase()
                    .includes(value.toLowerCase().trim())
                }
                {...form.getInputProps("brand")}
              />
            </div>
            <div>
              <Select
                style={{ display: form.isDirty("brand") ? "block" : "none" }}
                label="Típus"
                placeholder="Válassz típust"
                searchable
                nothingFound="No options"
                data={["Típus 1", "Típus 2", "Típus 3"]}
                {...form.getInputProps("model")}
              />
            </div>
          </SimpleGrid>

          <Box mt={60}>
            <Slider
              labelAlwaysOn
              defaultValue={1970}
              min={1970}
              max={2022}
              color="dark"
              {...form.getInputProps("year")}
            />
            <Text fz="sm">Évjárat</Text>
          </Box>
        </Card>
        <Button
          className={classes.sendButton}
          mt={25}
          type="submit"
          fullWidth
          color="dark"
          leftIcon={<FaSave />}
        >
          Mentés
        </Button>
      </form>
    </>
  );
};

export default QuestionForm;
