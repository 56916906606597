import { Settings, Logout } from "tabler-icons-react";
import Link from "next/link";
import { Menu, Avatar, NavLink } from "@mantine/core";

import styles from "../../styles/profileBtn.module.css";

import useAuth from "../../lib/auth";

import { useSelector } from "react-redux";
import { selectUser } from "../../lib/features/userSlice";

const ProfileBtn = () => {
  const user = useSelector(selectUser);

  const { userLogout } = useAuth();

  return (
    <>
      <Menu
        shadow="md"
        position="top-end"
        offset={1}
        withArrow
        closeOnClickOutside={true}
      >
        <Menu.Target>
          {user.photo ? (
            <Avatar
              className={styles.profileBtn}
              radius="xl"
              src={user.photo}
              alt={user.name}
            />
          ) : (
            <Avatar
              radius="xl"
              color="dark"
              className={styles.userAvatarBtn}
              alt={user.name}
            />
          )}
        </Menu.Target>

        <Menu.Dropdown>
          <Menu.Item icon={<Settings size={20} />}>
            <Link
              href="/profile"
              legacyBehavior
            >
              <a style={{ textDecoration: "none", color: "black" }}>
                Profil kezelèse
              </a>
            </Link>
          </Menu.Item>
          <NavLink
            label="Kilèpès"
            icon={<Logout size={20} />}
            onClick={() => userLogout()}
          />
        </Menu.Dropdown>
      </Menu>
    </>
  );
};

export default ProfileBtn;
