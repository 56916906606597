import React, { useState, useEffect } from "react";
import axios from '../lib/axios';
import { useRouter } from 'next/router';
import { showNotification } from '@mantine/notifications';
import Cookies from 'js-cookie';

import { useDispatch } from "react-redux";

export default function useAuth() {
   const router = useRouter();
   const dispatch = useDispatch();

   const csrf = async () => {
      await axios.get('/sanctum/csrf-cookie');
      //console.log('csrf = ', csrf);
   };

   const userLogin = async ({ ...props }) => {
      await csrf();

      axios
         .post('/api/public/auth/login', props)
         .then(response => {
            if (response.status === 200) {
               showNotification({
                  title: 'Bejelentkezés',
                  message: 'Sikeres bejelentkezés!',
                  color: 'teal'
               });

               getUserData();

               router.back();
            }
         })
         .catch(error => {
            console.log(error);
            if (error?.response?.status != 422) throw error;

            //console.log(Object.values(error.response.data.errors).flat());

            if (error.response.status === 401) {
               showNotification({
                  title: 'Hiba a bejelentkezéskor',
                  message: 'Hibás felhasználónèv vagy jelszó!',
                  color: 'red'
               });
            }
            else {
               showNotification({
                  title: 'Hiba a bejelentkezéskor',
                  message: 'Hiba a bejelentkezés közben, kèrem próbálja újra!',
                  color: 'red'
               });
            }
         });

      //console.log(props);
   };

   const userLogout = async () => {
      const logout = await axios.get('/api/user/logout');

      Cookies.remove('laravel_session');
      Cookies.remove('XSRF-TOKEN');
      Cookies.remove('_LIN');

      dispatch({
         type: "user/logout",
         user: { auth: false, name: null, photo: null },
      });

      router.push('/');
   };

   const getUserData = async () => {
      const user = await axios.get('/api/user').then(response => {
         dispatch({
            type: "user/setUserDefaultData",
            user: { auth: true, name: response.data.name, photo: null },
         });

         return response.data;
      }).catch(error => {
         console.log(error);
         if (error?.response?.status == 401) {

            dispatch({
               type: "user/logout",
               user: { auth: false, user_id: null, name: null, photo: null },
            });

            return error.response;
         }
      });
   };

   const userIsLoggedIn = async () => {
      await csrf();

      const user = await axios.get('/api/logged-in');
      try {
         return (user?.data?.check ? true : false);
      } catch (error) {
         return false;
      }
   };

   return {
      csrf,
      userLogin,
      userLogout,
      userIsLoggedIn,
      getUserData
   };
};