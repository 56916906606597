import { configureStore } from '@reduxjs/toolkit';
import userReducer from '../lib/features/userSlice';
import feedFilterReducer from '../lib/features/feedSlice';


export default configureStore({
   reducer: {
      user: userReducer,
      feedFilter: feedFilterReducer
   },
});