import LoginButton from "./loginButton";
import ProfileBtn from "./profileBtn";

type LoggedInBtnProps = {
  loggedIn: boolean;
};
const LoggedInBtn = ({ loggedIn }: LoggedInBtnProps) => {
  if (!loggedIn) {
    return <LoginButton />;
  }

  return <ProfileBtn />;
};

export default LoggedInBtn;
