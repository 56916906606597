import { Group, Button, MediaQuery } from "@mantine/core";
import AddBtn from "./layouts/addButton";
import LoggedInBtn from "./layouts/loggedInBtn";
import { GoSearch } from "react-icons/go";
import { useSpotlight } from "@mantine/spotlight";
import Link from "next/link";
import { FaHome } from "react-icons/fa";
import footerStyles from "../components/styles/footerStyle";

import { useSelector } from "react-redux";
import { selectUser } from "../lib/features/userSlice";

const useStyles = footerStyles;

const FooterMenu = () => {
  const { classes } = useStyles();

  const user = useSelector(selectUser);

  const spotlight = useSpotlight();

  return (
    <MediaQuery
      largerThan="md"
      styles={{ display: "none !important" }}
    >
      <div className={classes.footerRoot}>
        <Group
          position="apart"
          className={classes.footerInner}
        >
          <Link href="/">
            <Button
              variant="outline"
              className={classes.homeBtn}
            >
              <FaHome></FaHome>
            </Button>
          </Link>
          <Button
            variant="outline"
            className={classes.searchBtn}
            onClick={spotlight.openSpotlight}
          >
            <GoSearch></GoSearch>
          </Button>
          <AddBtn></AddBtn>
          <LoggedInBtn loggedIn={user.auth} />
        </Group>
      </div>
    </MediaQuery>
  );
};

export default FooterMenu;
