import { createSlice } from '@reduxjs/toolkit';

export const feedFilterSlice = createSlice({
   name: 'feedFilter',
   initialState: {
      group: 0,
      carBrand: null,
   },
   reducers: {
      setListByGroup: (state, action) => {
         state.group = action.feedFilter.group;
      },
      setListByCar: (state, action) => {
         state.carBrand = action.feedFilter.brand;
      },
   },
});

export const selectFeedFilter = (state) => state.feedFilter;

export default feedFilterSlice.reducer;