import { createStyles } from "@mantine/core";

const AddButtonStyles = createStyles((theme) => ({
  addBtn: {
    border: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.gray[7] : "#FFDD00"
    }`,
    color: `${theme.colorScheme === "dark" ? theme.colors.gray[2] : "#000"}`,
    paddingLeft: 10,
    paddingRight: 10,

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark" ? theme.colors.dark[3] : "#FFDD00",
    },
  },

  addBtnTxt: {
    marginLeft: 5,
    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      display: "none",
    },
  },
}));

export default AddButtonStyles;
